
import ResourceViewer from "@/components/Calendar/ResourceViewer.vue";
import {bus} from "@/modules/eventBus";
import {useApplication} from "@/store/pinia/applicationStore";
import {defineComponent} from "vue";

export default defineComponent({
  setup() {
    const applicationStore = useApplication()
    return {applicationStore}
  },
  components: {ResourceViewer},
  methods: {
    fetch() {
      bus.emit('availability:refresh')
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
})
